/* Add here all your CSS customizations */
.aa-input-container {
  display: inline-block;
  position: relative;
}

.aa-input-search {
  width: 300px;
  padding: 7px 15px;
  /* padding: 12px 28px 12px 12px; */
  margin-top: 5px;
  border: 1px solid #e4e4e4;
  /* border: 3px solid #e4e4e4; */
  border-radius: none;
  /* border-radius: 4px; */
  -webkit-transition: .2s;
  transition: .2s;
  font-family: 'Pathway Gothic One';
  /*font-family: "Didact Gothic", sans-serif;*/
  box-shadow: none;
  /* box-shadow: 4px 4px 0 rgba(241, 241, 241, 0.35); */
  font-size: 18px;
  /*font-size: 11px;*/
  box-sizing: border-box;
  color: #333;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  letter-spacing: 1px;
  /* ajout */
}

.ais-search-box--magnifier {
  display: none !important;
}
.ais-search-box--reset-wrapper {
  display: none !important;
}

.aa-input-search::-webkit-search-decoration,
.aa-input-search::-webkit-search-cancel-button,
.aa-input-search::-webkit-search-results-button,
.aa-input-search::-webkit-search-results-decoration {
  display: none;
}

.aa-input-search:focus {
  outline: 0;
  border-color: #3a96cf;
  box-shadow: 4px 4px 0 rgba(58, 150, 207, 0.1);
}

.aa-input-icon {
  height: 16px;
  width: 16px;
  position: absolute;
  top: 56%;
  /* top: 50%; */
  right: 16px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  fill: #e4e4e4;
}

.aa-hint {
  color: #e4e4e4;
}

.aa-dropdown-menu {
  background-color: #fff;
  border: 3px solid rgba(228, 228, 228, 0.6);
  border-top-width: 1px;
  font-family: "Didact Gothic", sans-serif;
  width: 300px;
  margin-top: 10px;
  box-shadow: 4px 4px 0 rgba(241, 241, 241, 0.35);
  font-size: 11px;
  border-radius: 4px;
  box-sizing: border-box;
}

.aa-suggestion {
  padding: 12px;
  border-top: 2px solid rgba(228, 228, 228, 0.6);
  cursor: pointer;
  -webkit-transition: .2s;
  transition: .2s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.aa-suggestion:hover,
.aa-suggestion.aa-cursor {
  background-color: rgba(241, 241, 241, 0.35);
}

.aa-suggestion > span:first-child {
  color: #333;
}

.aa-suggestion > span:last-child {
  /*text-transform: uppercase;*/
  color: #a9a9a9;
}

.aa-suggestion > span:first-child em,
.aa-suggestion > span:last-child em {
  font-weight: 700;
  font-style: normal;
  background-color: rgba(58, 150, 207, 0.1);
  padding: 2px 0 2px 2px;
}

.banniere {
  background-color: #9e1c2f;
  padding: 10px 0 1px 0;
}

.banniere p {
  color: white !important;
}

.ais-pagination {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding-left: 0;
  border-radius: 4px;
}
.ais-pagination > li {
  display: inline;
  line-height: 24px;
  text-align: -webkit-match-parent;
}
.ais-pagination > li.ais-pagination--item__active a.ais-pagination--link {
  min-width: 26px;
  padding: 3px 6px;
  margin-left: 5px;
  text-align: center;
  border-radius: 0px;
  background-color: #CCC;
  border: solid 1px #CCC;
  z-index: 3;
  color: #fff;
  cursor: default;
  position: relative;
  float: left;
  line-height: 1.42857143;
  text-decoration: none;
}
.ais-pagination > li .ais-pagination--link {
  min-width: 26px;
  padding: 3px 6px;
  margin-left: 5px;
  text-align: center;
  border-radius: 0px;
  background-color: #fff;
  border: solid 1px #CCC;
  z-index: 3;
  color: #ccc;
  cursor: pointer;
  position: relative;
  float: left;
  line-height: 1.42857143;
  text-decoration: none;
}
.ais-pagination > li .ais-pagination--link:hover, .ais-pagination > li .ais-pagination--link:focus {
  background-color: rgba(0, 0, 0, 0.1);
}

#clear-all {
  margin: 5px 0 5px 0;
}

.facet-count {
  padding-left: 5px;
}

.ais-refinement-list--checkbox {
  margin: 5px !important;
}

.ais-menu--item.ais-menu--item__active {
  font-weight: bold;
}

.facet-item.active {
  font-weight: bold;
}

.ais-price-ranges--list {
  display: none !important;
}

.ais-price-ranges--item {
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
}

.ais-price-ranges--item__active {
  font-weight: bold;
}

.ais-price-ranges--form {
  margin-top: 10px;
}

.ais-price-ranges--input {
  background: #FFFFFF;
  box-shadow: inset 0 1px 1px 0 rgba(85, 95, 110, 0.2);
  border: solid 1px #D4D8E3;
  border-radius: 4px;
  outline: none;
}

.ais-price-ranges--button {
  border: 0;
  outline: none;
  margin-left: 5px;
  position: relative;
  top: -2px;
}

.ais-range-input--inputMin,
.ais-range-input--inputMax {
  background: #FFFFFF;
  box-shadow: inset 0 1px 1px 0 rgba(85, 95, 110, 0.2);
  border: solid 1px #D4D8E3;
  border-radius: 4px;
  outline: none;
}

.ais-range-input--submit {
  border: none;
  outline: none;
  margin-left: 5px;
  position: relative;
  top: -2px;
}

.ais-range-slider--handle {
  border: 1px solid #9e1c2f;
}

.ais-range-slider .rheostat-progress {
  background-color: #9e1c2f !important;
}

.ais-range-input--fieldset {
  /* custom fieldset */
  margin: 0;
  padding: 0;
  border: 0;
}

.ais-range-input--labelMin {
  /* custom label min */
  display: inline-block;
}

.ais-range-input--inputMin {
  /* custom input min */
  min-width: 165px;
}

.ais-range-input--inputMin:hover:disabled {
  cursor: not-allowed;
}

.ais-range-input--separator {
  /* separator */
  margin: 0 5px;
}

.ais-range-input--labelMax {
  /* custom label max */
  display: inline-block;
}

.ais-range-input--inputMax {
  /* custom input max */
  min-width: 165px;
}

.ais-range-input--inputMax:hover:disabled {
  cursor: not-allowed;
}

.ais-range-input--submit {
  /* custom form button */
  margin-left: 5px;
}

.ais-range-input--submit:disabled,
.ais-range-input--submit:hover:disabled {
  cursor: not-allowed;
  background-color: #C9C9C9;
}

.ais-range-slider .ais-range-slider--disabled {
  cursor: not-allowed;
}

.ais-range-slider .ais-range-slider--disabled .ais-range-slider--handle {
  border-color: #DDD;
  cursor: not-allowed;
}

.ais-range-slider .ais-range-slider--disabled .rheostat-horizontal .rheostat-progress {
  background-color: #DDD;
}

.ais-range-slider .rheostat {
  overflow: visible;
  margin-top: 2em;
  margin-bottom: 2em;
}

.ais-range-slider .rheostat-background {
  background-color: #FFFFFF;
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
  border-left: 2px solid #DDD;
  border-right: 2px solid #DDD;
  position: relative;
}

.ais-range-slider .rheostat-horizontal .rheostat-background {
  height: 6px;
  top: 0;
  width: 100%;
}

.ais-range-slider .rheostat-horizontal .rheostat-progress {
  background-color: #46AEDA;
  position: absolute;
  height: 4px;
  top: 1px;
}

.ais-range-slider .rheostat-horizontal .rheostat-handle {
  margin-left: -5px;
  top: -7px;
}

.ais-range-slider .rheostat-horizontal .rheostat-handle .ais-range-slider--tooltip {
  text-align: center;
  margin-left: -10px;
  width: 40px;
}

.ais-range-slider .rheostat-horizontal .rheostat-handle::before,
.ais-range-slider .rheostat-horizontal .rheostat-handle::after {
  top: 7px;
  height: 10px;
  width: 1px;
}

.ais-range-slider .rheostat-horizontal .rheostat-handle::before {
  left: 10px;
}

.ais-range-slider .rheostat-horizontal .rheostat-handle::after {
  left: 13px;
}

.ais-range-slider--handle {
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  border: 1px solid #46AEDA;
  border-radius: 50%;
  cursor: pointer;
}

.ais-range-slider--tooltip {
  position: absolute;
  top: -22px;
  font-size: .8em;
  background: none;
}

.ais-range-slider--value {
  width: 40px;
  position: absolute;
  text-align: center;
  margin-left: -20px;
  padding-top: 15px;
  font-size: .8em;
}

.ais-range-slider--marker {
  position: absolute;
  background: #DDD;
  margin-left: -1px;
  width: 1px;
  height: 5px;
}

#prices {
  padding: 0 10px 0 5px;
}

.ais-show-more.ais-show-more__inactive {
  float: right;
  cursor: pointer;
}

.ais-show-more.ais-show-more__active {
  float: right;
  cursor: pointer;
}

.ais-hierarchical-menu--header {
  /* widget header */
}

.ais-hierarchical-menu--body {
  /* widget body */
}

.ais-hierarchical-menu--list {
  /* item list */
}

.ais-hierarchical-menu--list__lvl0 {
  /* item list level 0 */
}

.ais-hierarchical-menu--list__lvl1 {
  /* item list level 1 */
  margin-left: 10px;
}

.ais-hierarchical-menu--list__lvl2 {
  /* item list level 0 */
  margin-left: 10px;
}

.ais-hierarchical-menu--item {
  /* list item */
}

.ais-hierarchical-menu--item__active {
  /* active list item */
}

.ais-hierarchical-menu--link {
  /* item link */
}

.ais-hierarchical-menu--count {
  /* item count */
}

.ais-hierarchical-menu--footer {
  /* widget footer */
}

aside #colors::after {
  content: "";
  display: table;
  clear: both;
}
aside #colors::after .facet-color {
  width: 28px;
  height: 28px;
  border-radius: 3px;
  margin: 0 6px 6px;
  display: block;
  overflow: hidden;
  float: left;
}
aside #colors::after .facet-color.checked {
  box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 0.5);
}
aside #colors::after .facet-color[data-facet-value="Blanc"] {
  background-color: #FFFFFF;
  box-shadow: inset 0 0 0 1px #CCC;
}
aside #colors::after .facet-color[data-facet-value="Blanc"].checked {
  box-shadow: inset 0 0 0 4px rgba(0, 0, 0, 0.1);
}
aside #colors::after .facet-color[data-facet-value="Noir"] {
  background-color: #000000;
}
aside #colors::after .facet-color[data-facet-value="Rose"] {
  background-color: #C0116D;
}
aside #colors::after .facet-color[data-facet-value="Gris"] {
  background-color: #6F6E6C;
}
aside #colors::after .facet-color[data-facet-value="Bleu"] {
  background-color: #5182A1;
}
aside #colors::after .facet-color[data-facet-value="Vert"] {
  background-color: #1E9C5E;
}
aside #colors::after .facet-color[data-facet-value="Marron"] {
  background-color: #7B6A63;
}
aside #colors::after .facet-color[data-facet-value="Rouge"] {
  background-color: #BC1C1A;
}
aside #colors::after .facet-color[data-facet-value="Gold (or)"] {
  background-color: #AAAAA8;
}
aside #colors::after .facet-color[data-facet-value="Caramel"] {
  background-color: #C19A6E;
}
aside #colors::after .facet-color[data-facet-value="Beige"] {
  background-color: #C9B8A3;
}
aside #colors::after .facet-color[data-facet-value="Turquoise"] {
  background-color: #46BCC9;
}
aside #colors::after .facet-color[data-facet-value="Jaune"] {
  background-color: #F5E500;
}
aside #colors::after .facet-color[data-facet-value="Taupe"] {
  background-color: #74523E;
}
aside #colors::after .facet-color[data-facet-value="Bordeau"] {
  background-color: #51101E;
}
aside #colors::after .facet-color[data-facet-value="Orange"] {
  background-color: #FFA500;
}
aside #colors::after .facet-color[data-facet-value="Kaki"] {
  background-color: #f0e68c;
}
aside #colors::after .facet-color[data-facet-value="Anthrazit"] {
  background-color: #2B292E;
}
aside #colors::after .facet-color[data-facet-value="Bronze"] {
  background-color: #cd7f32;
}
aside #colors::after .facet-color[data-facet-value="Mauve"] {
  background-color: #B784A7;
}

.ais-toggle--item__active {
  font-weight: bold;
}

.ais-current-refined-values--item,
.ais-hierarchical-menu--item,
.ais-menu--item,
.ais-refinement-list--item,
.ais-star-rating--item,
.ais-toggle--item,
.ais-geo-search--toggle-label {
  font-size: 14px;
  line-height: 30px;
}

.ais-current-refined-values--link,
.ais-hierarchical-menu--link,
.ais-menu--link,
.ais-refinement-list--label,
.ais-toggle--label,
.ais-geo-search--toggle-label,
.ais-price-ranges--link,
.ais-star-rating--link {
  color: #9e1c2f;
  text-decoration: none;
}

.ais-current-refined-values--link:hover,
.ais-hierarchical-menu--link:hover,
.ais-menu--link:hover,
.ais-refinement-list--label:hover,
.ais-toggle--label:hover,
.ais-geo-search--toggle-label:hover,
.ais-price-ranges--link:hover,
.ais-star-rating--link:hover {
  color: #9e1c2f;
  text-decoration: none;
}

.ais-current-refined-values--count,
.ais-hierarchical-menu--count,
.ais-menu--count,
.ais-refinement-list--count,
.ais-star-rating--count,
.ais-toggle--count {
  background: rgba(39, 81, 175, 0.1);
  border-radius: 31px;
  color: #9e1c2f;
  padding: 2px 10px;
}

.ais-refinement-list--item {
  line-height: 24px;
}

.ais-refinement-list--item__active > div > .ais-refinement-list--label,
.ais-refinement-list--item__active > div > .ais-toggle--label,
.ais-refinement-list--item__active > div > .ais-geo-search--toggle-label {
  font-weight: bold;
}

.ais-refinement-list--label,
.ais-toggle--label,
.ais-geo-search--toggle-label {
  cursor: pointer;
}

.ais-refinement-list--label input[type="radio"],
.ais-toggle--label input[type="radio"],
.ais-geo-search--toggle-label input[type="radio"],
.ais-refinement-list--label input[type="checkbox"],
.ais-toggle--label input[type="checkbox"],
.ais-geo-search--toggle-label input[type="checkbox"] {
  margin-right: 5px;
}

.ais-toggle--label {
  font-weight: normal;
}

/*# sourceMappingURL=custom.css.map */
